@import url("tailwindcss/base");
@import url("tailwindcss/components");
@import url("tailwindcss/utilities");
@import url("./_font.css");
@import url("./_form.css");
@import url("./_utilities.css");

.responsive-object {
  position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@layer utilities {
  .prose :where(iframe):not(:where([class~="not-prose"] *)) {
    @apply aspect-video w-full h-auto;
  }
}

/* Landing Page Card Components */
.card .icon-wrapper {
  @apply flex justify-center items-center w-12 h-12 bg-white bg-opacity-50 rounded-md;
}

.card .icon {
  @apply w-6 h-6;
}

.card .info {
  @apply mt-6;
}

.card h3 {
  @apply text-lg font-bold;
}

.card .info p {
  @apply mt-2 text-base font-normal;
}

/* Navigation Bar */
.org-mobile-menu-btn {
  @apply inline-flex justify-center items-center p-2 rounded bg-green-400 text-green-900 hover:bg-green-300 hover:text-green-950;
}

.org-current-page {
  @apply bg-green-400;
}

.org-mobile-nav {
  @apply text-green-950;
}

.org-mobile-nav a {
  @apply block px-3 py-2 rounded-md text-base font-medium hover:bg-green-400;
}

/* Navigation Profile */
.org-profile {
  @apply pt-4 pb-3 border-t border-green-900 text-green-950;
}

.org-profile-nav {
  @apply px-2 mt-3 space-y-1 font-bold;

  color: inherit;
}

.org-profile-nav a {
  @apply block py-1 px-3 text-base rounded-md hover:bg-green-400;
}
