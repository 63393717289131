@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

@font-face {
  font-family: "TradeGothicLTPro-BdCn20";
  font-display: swap;
  src: url("/static/fonts/TradeGothicLTPro-BdCn20/font.woff2") format("woff2"),
    url("/static/fonts/TradeGothicLTPro-BdCn20/font.woff") format("woff");
}

@font-face {
  font-family: TradeGothicLTPro;
  font-display: swap;
  src: url("/static/fonts/TradeGothicLTPro/font.woff2") format("woff2"),
    url("/static/fonts/TradeGothicLTPro/font.woff") format("woff");
}
